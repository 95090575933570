// Colors
$color-white: #ffffff;
$color-black: #0b1c23;
$color-primary: #3baec9;
$color-secondary: #95daea;

$color-gray-100: #f7f7f7;
$color-gray-200: #efefef;
$color-gray-300: #e0e0e0;
$color-gray-400: #b0b0b0;
$color-gray-500: #657687;

$color-text-grey: $color-black;

$theme-max-width: 1366px;
$theme-colors: (
  'info': tomato,
  'primary': teal,
);

$link-color: $color-primary;

// Font Sizes
$font-size-base: 1.125rem;

// breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Spacers
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 2,
  5: $spacer * 3,
);

// Bootstrap overrides
$body-color: $color-text-grey;
$body-bg: $color-gray-100;

$font-family-sans-serif: 'Montserrat', sans-serif;
$font-family-base: $font-family-sans-serif;
